import { Options, Vue } from "vue-class-component";
import { message } from "ant-design-vue";
import { RuleObject } from "ant-design-vue/lib/form/interface";
// eslint-disable-next-line import/no-unresolved
import {
    IJeecgPhoneLoginParams,
    JeecgAuthService,
    LoginUser,
    CookieStorage,
} from "@do/do-auth-web-client";
import { DingdingOutlined } from "@ant-design/icons-vue";
import ForgetPwd from "@/components/forgetPwd/index.vue";
import { getUrlValues } from "@/qs";
import Tryout from "@/components/tryout/index.vue";
import { getLoginType, getRedirectUrl } from "../../utils";

@Options({
    components: { DingdingOutlined, Tryout, ForgetPwd },
})
export default class App extends Vue {
    tabs = ["密码登录", "短信登录"];

    companys = [];

    curCompanyIndex = 0;

    curIndex = 0;

    token = "";

    loginForm = {
        from: "web",
        phone: "",
        password: "",
        newPassword: "",
        surePassword: "",
        code: "",
        loginFree: false,
    };

    loginFormPhone = { from: "web", phone: "", code: "", loginFree: false };

    codeImg = ``;

    btnTxt = "获取验证码";

    intervalId!: any;

    disabled = false;

    flag = false; // 密码隐藏

    ifLogin = false;

    logined = false; // 已经登录了

    isFirst = false; // 第一次登录

    isHaveMore = false; // 是否所属好多公司

    forgetFlag = false; // 忘记密码

    time = 60;

    currdatetime = "";

    cookieStorage: CookieStorage = new CookieStorage();

    tryouotVisible = false;

    desktop = false;

    isCommunity = false;

    trialUrl = "";

    returnUrl = "";

    mounted() {
        this._setCodeurl();
        const { type, isCommunity, returnUrl } = getUrlValues();
        this.returnUrl = returnUrl as string;
        this.isCommunity = isCommunity == "1";
        this.trialUrl = `${window.ENV_CONFIG.officialUrl}/trial`


        if (type === "desktop") {
            this.desktop = true;
        }
    }

    validatePass = async (rule: RuleObject, value: string) => {
        if (value === "") {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject("请再次输入密码");
        }
        if (value !== this.loginForm.newPassword) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject("两次输入不一致");
        }
        return Promise.resolve();
    };

    get disabledLogin(): boolean {
        if (!this.logined) {
            if (this.curIndex === 0) {
                const { phone, password, code } = this.loginForm;
                if (phone && password && code) {
                    this.ifLogin = false;
                    return this.ifLogin;
                }
                this.ifLogin = true;
                return this.ifLogin;
            }
            const { phone, code } = this.loginFormPhone;
            if (phone && code) {
                this.ifLogin = false;
                return this.ifLogin;
            }
        }
        this.ifLogin = false; // TODO:后续需要改
        return this.ifLogin;
    }

    set disabledLogin(flag: boolean) {
        this.ifLogin = flag;
    }

    get rules(): Record<string, unknown> {
        if (!this.logined) {
            if (this.curIndex === 0) {
                return {
                    phone: [
                        { required: true, message: "请输入手机号", trigger: ["change", "blur"] },
                        {
                            pattern: /^1[0-9]{10}$/,
                            message: "请输入正确格式",
                            trigger: ["change", "blur"],
                        },
                    ],
                    password: [
                        { required: true, message: "请输入密码", trigger: ["change", "blur"] },
                    ],
                    code: [
                        { required: true, message: "请输入验证码", trigger: ["change", "blur"] },
                    ],
                };
            }
            return {
                phone: [
                    { required: true, message: "请输入手机号", trigger: ["change", "blur"] },
                    {
                        pattern: /^1[0-9]{10}$/,
                        message: "请输入正确格式",
                        trigger: ["change", "blur"],
                    },
                ],
                code: [{ required: true, message: "请输入验证码", trigger: ["change", "blur"] }],
            };
        }
        return {
            newPassword: [{ required: true, message: "请设置密码", trigger: "blur" }],
            surePassword: [
                { required: true, message: "请再次输入密码", trigger: "blur" },
                { validator: this.validatePass, trigger: "blur" },
            ],
        };
    }

    // public async mounted() {
    //     const token = getCookie();
    //     const params: IToken = {
    //         from: 'web',
    //         token,
    //     };
    //     const { code, data } = await post('/authentication/login/analysisToken', params);
    //     if (code === 200 && data.userId) {
    //         window.location.href = this._getRedirectUrl(token);
    //     }
    // }

    private _changeIndex(index: number): void {
        this.curIndex = index;
        if (this.curIndex === 0) {
            this.loginForm.phone = "";
            this.loginForm.password = "";
            this.loginForm.code = "";
        } else {
            this.loginFormPhone.phone = "";
            this.loginFormPhone.code = "";
        }
    }

    private async _setCodeurl(): Promise<void> {
        this.currdatetime = new Date().getTime().toString();
        this.codeImg = await JeecgAuthService.getRandomCode(this.currdatetime);
    }

    private _remberHandel(msg: string): void {
        if (msg) {
            this.loginFormPhone.loginFree = !this.loginFormPhone.loginFree;
            return;
        }
        this.loginForm.loginFree = !this.loginForm.loginFree;
    }

    private async _enterPlatform(): Promise<void> {
        await (this.$refs.formEditRef as ANY).validate();
        const params = {
            password: this.loginForm.newPassword,
        };
        await JeecgAuthService.resetUserPassword(params);
        window.location.href = await getRedirectUrl();
    }

    private async _login(): Promise<void> {
        if (this.disabledLogin) {
            return;
        }
        this.disabledLogin = true;

        // 用户名密码登录
        if (this.curIndex === 0) {
            await (this.$refs.formRef as ANY).validate();
            this._loginByPassword();
            return;
        }

        // 手机登录
        await (this.$refs.formPhoneRef as ANY).validate();
        this._loginFromPhone();
    }

    // private async _chooseCompany() {
    //     const params = {
    //         token: this.token,
    //         groupId: (this.companys[this.curCompanyIndex] as KV).number,
    //     };
    //     const { token } = await AuthService.chooseCompany(params);
    //     this.token = token;
    //     localStorage.setItem('token', token);
    //     if (token && token !== '') {
    //         saveToken(token, 7);
    //     }
    //     this.isHaveMore = false;
    //     if (!this.isFirst) {
    //         window.location.href = this._getRedirectUrl(token);
    //     }
    // }

    private async _loginByPassword(): Promise<void> {
        const params: any = {
            captcha: this.loginForm.code,
            remember_me: this.loginForm.loginFree,
            password: this.loginForm.password,
            username: this.loginForm.phone,
            checkKey: this.currdatetime,
            from: getLoginType(),
        };
        try {
            const loginResult = await JeecgAuthService.login(params);
            await this._processLogin(loginResult);
        } catch (error: ANY) {
            console.error(error);
            this._setCodeurl();
            message.error(error.message, 3);
        }
    }

    private async _loginFromPhone(): Promise<void> {
        try {
            const params: IJeecgPhoneLoginParams = {
                mobile: this.loginFormPhone.phone,
                captcha: this.loginFormPhone.code,
                remember_me: true,
                from: getLoginType(),
            };
            const loginResult = await JeecgAuthService.phoneLogin(params);
            await this._processLogin(loginResult);
        } catch (error: ANY) {
            console.error(error);
            message.error(error.message, 3);
        }
    }

    private async _processLogin(loginResult: any): Promise<void> {
        const { token, userInfo } = loginResult;

        const user: LoginUser = {
            token,
            user: {
                id: userInfo.id,
                avatar: userInfo.avatar,
                realname: userInfo.realname,
                username: userInfo.username,
                isCommunity: this.isCommunity
            },
        };
        this.cookieStorage.setUserInfo(user);
        this.token = token;
        this.logined = true;
        this.isFirst = userInfo.resetNextLogin;
        if (!this.isFirst) {
            window.location.href = await getRedirectUrl();
        }
    }

    private _countDown() {
        this.btnTxt = `${this.time}s`;
        this.disabled = true;
        this.intervalId = setInterval(() => {
            this.time--;
            this.btnTxt = `${this.time}s`;
            if (this.time <= 0) {
                clearInterval(this.intervalId);
                this.btnTxt = "获取验证码";
                this.disabled = false;
                if (this.time === 0) {
                    this.time = 60;
                }
            } else {
                this.disabled = true;
            }
        }, 1000);
    }

    private async _getCode(): Promise<void> {
        await (this.$refs.formPhoneRef as ANY).validate("phone");
        const { phone } = this.loginFormPhone;
        this._countDown();
        const key = "updatable";
        message.loading({ content: "短信发送中", key, duration: 0 });
        try {
            const data = await JeecgAuthService.sms({
                mobile: phone,
                smsmode: "0",
            });

            message.success({ content: "验证码已发送", key });
        } catch (ex: any) {
            message.error({ content: `短信发送失败: ${ex.message}`, key });
            clearInterval(this.intervalId);
            this.btnTxt = "获取验证码";
            this.disabled = false;
        } finally {
        }
    }

    private thirdType = "";

    private thirdLoginInfo = "";

    private thirdLoginState = false;

    private bindingPhoneModal = false;

    private thirdUserUuid?: string;

    private thirdConfirmShow?: boolean;

    onThirdLogin(source: string) {
        const url = `${window.ENV_CONFIG.baseUrl}/jeecg-boot/sys/thirdLogin/render/${source}`;
        window.open(
            url,
            `login ${source}`,
            "height=500, width=500, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
        );
        const that = this;
        that.thirdType = source;
        that.thirdLoginInfo = "";
        that.thirdLoginState = false;
        const receiveMessage = function (event: any) {
            const token = event.data;
            if (typeof token === "string") {
                // 如果是字符串类型 说明是token信息
                if (token === "登录失败") {
                    message.warn(token);
                } else if (token.includes("绑定手机号")) {
                    that.bindingPhoneModal = true;
                    const strings = token.split(",");
                    that.thirdUserUuid = strings[1];
                } else {
                    that.doThirdLogin(token);
                }
            } else if (typeof token === "object") {
                // 对象类型 说明需要提示是否绑定现有账号
                if (token.isObj === true) {
                    that.thirdConfirmShow = true;
                    that.thirdLoginInfo = { ...token };
                }
            } else {
                message.warn("不识别的信息传递");
            }
        };
        window.addEventListener("message", receiveMessage, false);
    }

    // 根据token执行登录
    private async doThirdLogin(token: string) {
        console.log(token);
        if (this.thirdLoginState === false) {
            this.thirdLoginState = true;

            const id = JSON.parse(atob(token.split(".")[1])).sub;

            let user: LoginUser;

            user = {
                token,
                user: {
                    id,
                    avatar: "",
                    realname: "",
                    username: "",
                },
            };
            this.cookieStorage.setUserInfo(user);
            const { avatar, realname, username } = await JeecgAuthService.keepAlive();
            user = {
                token,
                user: {
                    id,
                    avatar,
                    realname,
                    username,
                },
            };
            this.cookieStorage.setUserInfo(user);
            window.location.href = await getRedirectUrl();
        }
    }
}
